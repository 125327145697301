import React from "react"
import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import { theme } from "../../utils/theme"
import { buttonStyles } from "./button"

interface LinkProps {
  to: string
  button?: boolean
  arrow?: boolean
}

const linkStyles = css`
  color: var(--link-color);
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 0.75px;

  svg {
    position: relative;
    top: 5px;
    margin-left: ${theme.spacing}px;
    transform: translateX(0);
    transition: transform 200ms;
  }

  &:hover {
    svg {
      position: relative;
      top: 5px;
      margin-left: ${theme.spacing}px;
      transform: translateX(5px);
    }
  }
`

const StyledLink = styled(GatsbyLink)<LinkProps>`
  ${props => (props.button ? buttonStyles : linkStyles)}
`

export const Link: React.FC<LinkProps> = ({ children, to, arrow, button }) => (
  <StyledLink to={to} button={button}>
    {children}
    {arrow && (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="long-arrow-alt-right"
        className="svg-inline--fa fa-long-arrow-alt-right fa-w-14"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        width="17px"
      >
        <path
          fill="currentColor"
          d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"
        ></path>
      </svg>
    )}
  </StyledLink>
)
