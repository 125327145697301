import { motion } from "framer-motion"
import { graphql } from "gatsby"
import React from "react"
import { useInView } from "react-intersection-observer"
import { BlogIndexQuery } from "../../graphql-types"
import About from "../components/About"
import { BlogPreview } from "../components/BlogPreview"
import { Header } from "../components/Header"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Heading from "../assets/elements/heading"

interface BlogIndexProps {
  data: BlogIndexQuery
  location: Location
}

type Posts = BlogIndexQuery["allMarkdownRemark"]["edges"]

const HomeIndex: React.FC<BlogIndexProps> = props => {
  const { data } = props
  const siteTitle = data.site?.siteMetadata?.title || ""
  const posts: Posts = data.allMarkdownRemark.edges.slice(0, 2)
  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  return (
    <Layout location={props.location} title={siteTitle} noMargin={true}>
      <SEO title="Amber Brookhuis" />
      <Header />
      {/* <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{
          opacity: inView ? 1 : 0,
          transform: `translateY(${inView ? 0 : 10}px)`,
        }}
      >
        <BlogPreview posts={posts} />
      </motion.div> */}
    </Layout>
  )
}

export default HomeIndex

export const pageQuery = graphql`
  query HomeIndex {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
