import { motion } from "framer-motion"
import React, { useContext } from "react"
import Particles from "react-particles-js"
import styled from "styled-components"
import { Link } from "../../assets/elements/link"
import { device } from "../../utils/theme"
import { StateContext } from "../context"
import { StyledHeader } from "./styles"

const ParticlesContainer = styled.div`
  display: none;
  @media ${device.desktop.s} {
    display: block;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledIntro = styled.div`
  h1 {
    font-size: 2rem;
    color: var(--heading);
  }

  @media ${device.mobile.l} {
    h1 {
      font-size: 2.5rem;
    }
  }

  @media ${device.tablet.s} {
    h1 {
      font-size: 3rem;
    }
  }

  @media ${device.desktop.l} {
    h1 {
      font-size: 3.5rem;
    }
  }
`

export const Header: React.FC<{}> = () => {
  const { theme } = useContext(StateContext)
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      delay: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.075,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, transform: "translateY(10px)" },
    show: {
      opacity: 1,
      transform: "translateY(0px)",
      transition: { duration: 0.4 },
      type: "spring",
    },
  }

  return (
    <>
      <StyledHeader>
        <ParticlesContainer>
          <Particles
            params={{
              particles: {
                number: {
                  value: 50,
                },
                size: {
                  value: 2,
                },
                color: { value: theme === "dark" ? "#fff" : "#515151" },
                // eslint-disable-next-line @typescript-eslint/camelcase
                line_linked: { color: theme === "dark" ? "#fff" : "#858585" },
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "repulse",
                  },
                },
              },
            }}
          />
        </ParticlesContainer>
        <StyledIntro>
          <motion.div variants={container} initial="hidden" animate="show">
            <motion.h1 variants={item}>
              frontend developer <br />
              and designer based <br />
              in rotterdam
            </motion.h1>
            <motion.p variants={item}>
              My name is <Link to="/about">Amber</Link> and I’m a frontend
              developer living and working in Rotterdam, The Netherlands. I
              specialize in building web applications and designing meaningful
              interfaces.
            </motion.p>
            <motion.div variants={item}>
              <Link to={"/about"} button arrow>
                Get in touch
              </Link>
            </motion.div>
          </motion.div>
        </StyledIntro>
      </StyledHeader>
    </>
  )
}
