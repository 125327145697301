import styled from "styled-components"
import { theme } from "../../utils/theme"

export const StyledHeader = styled.div`
  position: relative;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${theme.spacing * 8}px;
`
