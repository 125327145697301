import React from "react"
import styled, { css } from "styled-components"
import { theme } from "../../utils/theme"

interface Props {
  onClick: (_: any) => any
}

export const buttonStyles = css`
  display: inline-block;
  background: linear-gradient(
    90deg,
    var(--button-gradient-1),
    var(--button-gradient-2)
  );
  color: #000;
  border: none;
  padding: 14px 24px;
  border-radius: ${theme.border.radius}px;
  transition: opacity 0.2s ease-out;
  opacity: 1;
  line-height: 1.15;
  cursor: pointer;

  &:hover {
    color: #000;
    opacity: 0.9;

    svg {
      transform: translateX(5px);
    }
  }

  svg {
    margin-left: 20px;
    position: relative;
    top: 4px;
    transform: translateX(0);
    transition: transform 200ms;
  }
`

const StyledButton = styled.button`
  ${buttonStyles};
`

export const Button: React.FC<Props> = ({ children, onClick }) => (
  <StyledButton onClick={onClick}>
    {children}
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="long-arrow-alt-right"
      className="svg-inline--fa fa-long-arrow-alt-right fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width="17px"
    >
      <path
        fill="currentColor"
        d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"
      ></path>
    </svg>
  </StyledButton>
)
